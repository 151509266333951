<template>
  <div>
    <div class="mb-3 flex items-center justify-between border-b pb-3">
      <div>
        <h2 class="text-xl font-semibold">{{ this.title }}</h2>
      </div>
      <div class="">
        <router-link :to="{name: 'UploadList'}"
                     class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
          Danh sách upload
        </router-link>
      </div>
    </div>
    <div v-if="prefix === 'tan-nhac' && !canUploadSong" class="content bg-red-500 border p-4 relative rounded-md uk-this.$toast.error">
      <p class="text-white">
        Chức năng gửi tác phẩm thể loại "Tân nhạc" trực tiếp cho Ban biên tập chưa khả dụng đối với bạn. Bạn vui lòng
        gửi tác phẩm thông qua diễn đàn bằng cách tạo 1 chủ đề mới ở chuyên mục
        <router-link :to="{name: 'DiscussionAdd', query: {forum_id: 28}}" class="">
          "Gửi tác phẩm"
        </router-link>
        <br/>
        <br/>
        Các tác phẩm đạt chất lượng, trùng với tiêu chí của trang nhạc sẽ được BBT đăng lên BCĐCNT.
      </p>
      <p>
        <img src="https://static.bcdcnt.net/assets/images/gui-tac-pham-1.png" class="w-full"/>
      </p>
      <p>
        <img src="https://static.bcdcnt.net/assets/images/gui-tac-pham-2.png" class="w-full"/>
      </p>
      <p>
        <img src="https://static.bcdcnt.net/assets/images/gui-tac-pham-3.png" class="w-full"/>
      </p>
    </div>
    <div class="lg:flex lg:space-x-10" v-else>
      <div class="lg:w-3/4">
        <div class="max-w-2xl m-auto shadow-md rounded-md bg-white mt-3">
          <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
            <div class="col-span-2"
                 v-if="upload.type === 'song' || upload.type === 'folk' || upload.type === 'instrumental' || upload.type === 'karaoke' || upload.type === 'poem'">
              <label for="title" class="font-semibold">Tên tác phẩm (*)</label>
              <input v-model="upload.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'document_image' || upload.type === 'document_audio' || upload.type === 'document_news'">
              <label for="title" class="font-semibold">Tên tư liệu (*)</label>
              <input v-model="upload.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2" v-if="upload.type === 'sheet'">
              <label for="title" class="font-semibold">Tên bản nhạc (*) <i class="far fa-question-circle"
                                                                           :uk-tooltip="'Tên bản nhạc phải trùng với tên bài hát đã có trên trang'"></i></label>
              <input v-model="upload.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2" v-if="upload.type === 'replace'">
              <label for="link" class="font-semibold">Link bài hát muốn thay thế (*)</label>
              <input v-model="upload.link" class="shadow-none with-border" id="link" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2" v-if="upload.type === 'instrumental'">
              <label for="song_type" class="font-semibold">Thể loại</label>
              <select v-model="upload.song_type" id="song_type" class="shadow-none  with-border">
                <option value="morden">Khí nhạc Việt Nam</option>
                <option value="classical">Khí nhạc nước ngoài</option>
              </select>
            </div>

            <div class="col-span-2" v-if="upload.type === 'karaoke'">
              <label for="karaoke_type" class="font-semibold">Thể loại (*)</label>
              <select v-model="upload.karaoke_type" id="karaoke_type" class="shadow-none  with-border">
                <option value="song">Tân nhạc</option>
                <option value="folk">Dân ca</option>
              </select>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'song' || upload.type === 'folk' || upload.type === 'karaoke'|| upload.type === 'poem'">
              <label for="content" class="font-semibold">Lời tác phẩm <i class="far fa-question-circle"
                                                                         :uk-tooltip="'Chỉ ghi lời bài hát, không ghi tên bài hát, người trình bày,...'"></i></label>
              <editor id="content" v-model:content="upload.content"/>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'document_image' || upload.type === 'document_audio'">
              <label for="content" class="font-semibold">Mô tả tư liệu (hiển thị công khai)</label>
              <editor id="content" v-model:content="upload.content"/>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'document_news'">
              <label for="content" class="font-semibold">Nội dung tư liệu (*)</label>
              <editor id="content" v-model:content="upload.content"/>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'sheet'">
              <label for="content" class="font-semibold">Ảnh các sheet nhạc (*) <i class="far fa-question-circle"
                                                                                   :uk-tooltip="'Chỉ chèn ảnh các sheet nhạc vào ô nội dung'"></i></label>
              <editor id="content" v-model:content="upload.content"/>
            </div>

            <div class="col-span-2" v-if="upload.type !== 'sheet'">
              <label for="note" class="font-semibold">Ghi chú của người gửi</label>
              <textarea class="shadow-none with-border" id="note" type="text"
                        v-model="upload.note" autocomplete="off"></textarea>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'folk' || (upload.type === 'karaoke' && upload.karaoke_type === 'folk')">
              <label class="font-semibold">Thể loại <i class="far fa-question-circle"
                                                       :uk-tooltip="'Chọn thể loại trong danh sách. Nếu không có trong danh sách, ghi vào ghi chú'"></i></label>
              <multiselect
                  v-model="upload.fcat_list_tmp"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchFcats"
              >
              </multiselect>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'folk' || (upload.type === 'karaoke' && upload.karaoke_type === 'folk')">
              <label class="font-semibold">Làn điệu <i class="far fa-question-circle"
                                                       :uk-tooltip="'Chọn làn điệu trong danh sách. Nếu không có trong danh sách, ghi vào ghi chú'"></i></label>
              <multiselect
                  v-model="upload.melody_list_tmp"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchMelodies"
              >
              </multiselect>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'song' || upload.type === 'instrumental' || (upload.type === 'karaoke' && upload.karaoke_type === 'song' || upload.type === 'document_image' || upload.type === 'document_audio' || upload.type === 'document_news')">
              <label class="font-semibold">{{
                  upload.type === 'document_image' || upload.type === 'document_audio' || upload.type === 'document_news' ? 'Nhạc sĩ liên quan' : 'Sáng tác'
                }} <i class="far fa-question-circle"
                      :uk-tooltip="'Chọn tác giả nhạc trong danh sách. Nếu không có trong danh sách, ghi vào ghi chú'"></i></label>
              <multiselect
                  v-model="upload.composer_list_tmp"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchComposers"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3" :src="option.icon"> {{ option.title }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.icon" class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'sheet'">
              <label class="font-semibold">Sáng tác (*) <i class="far fa-question-circle"
                                                           :uk-tooltip="'Chọn tác giả nhạc trong danh sách'"></i></label>
              <multiselect
                  v-model="upload.composer_list_tmp"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchComposers"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3" :src="option.icon"> {{ option.title }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.icon" class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'karaoke'">
              <label class="font-semibold">Trình bày (*) <i class="far fa-question-circle"
                                                            :uk-tooltip="'Chọn thành viên hát trong danh sách'"></i></label>
              <multiselect
                  v-model="upload.user_list_tmp"
                  mode="tags"
                  valueProp="id"
                  label="username"
                  trackBy="username"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchUsers"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3" :src="option.icon"> {{ option.username }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.icon" class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.username }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'folk' || (upload.type === 'karaoke' && upload.karaoke_type === 'folk' || upload.type === 'document_image' || upload.type === 'document_audio' || upload.type === 'document_news')">
              <label class="font-semibold">{{
                  upload.type === 'document_image' || upload.type === 'document_audio' || upload.type === 'document_news' ? 'Soạn giả liên quan' : 'Soạn lời'
                }} <i class="far fa-question-circle"
                      :uk-tooltip="'Chọn soạn giả trong danh sách. Nếu không có trong danh sách, ghi vào ghi chú'"></i></label>
              <multiselect
                  v-model="upload.recomposer_list_tmp"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchRecomposers"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3" :src="option.icon"> {{ option.title }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.icon" class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'song' || upload.type === 'poem' || (upload.type === 'karaoke' && upload.karaoke_type === 'song' || upload.type === 'document_image' || upload.type === 'document_audio' || upload.type === 'document_news')">
              <label class="font-semibold">{{
                  upload.type === 'document_image' || upload.type === 'document_audio' || upload.type === 'document_news' ? 'Nhà thơ liên quan' : upload.type === 'poem' ? 'Sáng tác' : 'Tác giả lời'
                }} <i
                    class="far fa-question-circle"
                    :uk-tooltip="'Chọn tác giả lời trong danh sách. Nếu không có trong danh sách, ghi vào ghi chú'"></i></label>
              <multiselect
                  v-model="upload.poet_list_tmp"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchPoets"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3" :src="option.icon"> {{ option.title }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.icon" class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'song' || upload.type === 'folk' || upload.type === 'instrumental' || upload.type === 'poem' || upload.type === 'document_image' || upload.type === 'document_audio' || upload.type === 'document_news'">
              <label class="font-semibold">{{
                  upload.type === 'document_image' || upload.type === 'document_audio' || upload.type === 'document_news' ? 'Nghệ sĩ liên quan' : 'Trình bày'
                }} <i class="far fa-question-circle"
                      :uk-tooltip="'Chọn người trình bày trong danh sách. Nếu không có trong danh sách, ghi vào ghi chú'"></i></label>
              <multiselect
                  v-model="upload.artist_list_tmp"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchArtists"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3" :src="option.icon"> {{ option.title }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.icon" class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'song' || upload.type === 'folk' || upload.type === 'instrumental' || upload.type === 'karaoke' || upload.type === 'poem'">
              <label for="year" class="font-semibold">Năm sáng tác</label>
              <input v-model="upload.year" class="shadow-none with-border" id="year" type="text" autocomplete="off">
            </div>

            <div class="col-span-2"
                 v-if="upload.type === 'song' || upload.type === 'folk' || upload.type === 'instrumental' || upload.type === 'karaoke' || upload.type === 'poem'">
              <label for="record_year" class="font-semibold">Năm thu âm</label>
              <input v-model="upload.record_year" class="shadow-none with-border" id="record_year" type="text"
                     autocomplete="off">
            </div>
          </div>

          <div class="border-t flex justify-center border-gray-100 p-6">
            <button type="button" class="button lg:w-1/3" v-on:click="submit">
              Gửi đi
            </button>
          </div>
        </div>
      </div>
      <div class="lg:w-1/4 w-full">
        <div
            v-if="upload.type === 'song' || upload.type === 'folk' || upload.type === 'instrumental' || upload.type === 'karaoke' || upload.type === 'poem' || upload.type === 'replace' || upload.type === 'document_audio'">
          <label class="font-semibold">File nhạc (*)</label>
          <DropZone type="music" v-model:objectIdProp="upload.file_id"/>
        </div>

        <div class="mt-4"
             v-if="upload.type === 'song' || upload.type === 'folk' || upload.type === 'instrumental' || upload.type === 'karaoke' || upload.type === 'poem' || upload.type === 'document_image' || upload.type === 'document_news'">
          <label class="font-semibold">{{ upload.type === 'document_image' ? 'Hình ảnh (*)' : 'Ảnh minh họa' }}</label>
          <DropZone type="image" v-model:objectIdProp="upload.image_id"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import Multiselect from '@vueform/multiselect'
import ApiService from "../../core/services/api.service";
import DropZone from "@/components/DropZone.vue";
import {canUploadSong, getUploadTitle, getUploadType} from "../../core/services/utils.service";
import {getUser} from "../../core/services/jwt.service";
import Editor from "../Editor";

export default {
  title: "UploadAdd",
  components: {
    Editor,
    Multiselect, DropZone
  },
  data() {
    return {
      prefix: "",
      url: "",
      title: "",
      upload: {
        title: "",
        content: "",
        note: "",
        type: "",
        composer_list_tmp: [],
        user_list_tmp: [],
        artist_list_tmp: [],
        poet_list_tmp: [],
        recomposer_list_tmp: [],
        fcat_list_tmp: [],
        melody_list_tmp: [],
        composer_list: "",
        user_list: "",
        artist_list: "",
        poet_list: "",
        recomposer_list: "",
        fcat_list: "",
        melody_list: "",
        composers: "",
        users: "",
        artists: "",
        poets: "",
        recomposers: "",
        fcats: "",
        melodies: "",
        pre_composers: "",
        pre_users: "",
        pre_artists: "",
        pre_poets: "",
        pre_recomposer: "",
        pre_fcat: "",
        pre_melody: "",
        year: "",
        record_year: "",
        file_id: null,
        image_id: null,
        song_type: "new",
        folk_type: "",
        karaoke_type: "song",
        link: ""
      }
    }
  },
  methods: {
    async searchComposers(q) {
      let query = `query($q: Mixed) {
        composers(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.composers && data.data.composers.data) {
              data.data.composers.data.map((item) => {
                item.icon = item.avatar ? item.avatar.url : "https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg";
                item.value = {
                  id: item.id,
                  title: item.title
                }
              });
              return data.data.composers.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              data.data.users.data.map((item) => {
                item.icon = item.avatar ? item.avatar.url : "https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg";
                item.value = {
                  id: item.id,
                  username: item.username
                }
              });
              return data.data.users.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchArtists(q) {
      let query = `query($q: Mixed) {
        artists(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.artists && data.data.artists.data) {
              data.data.artists.data.map((item) => {
                item.icon = item.avatar ? item.avatar.url : "https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg";
                item.value = {
                  id: item.id,
                  title: item.title
                }
              });
              return data.data.artists.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchPoets(q) {
      let query = `query($q: Mixed) {
        poets(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.poets && data.data.poets.data) {
              data.data.poets.data.map((item) => {
                item.icon = item.avatar ? item.avatar.url : "https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg";
                item.value = {
                  id: item.id,
                  title: item.title
                }
              });
              return data.data.poets.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchRecomposers(q) {
      let query = `query($q: Mixed) {
        recomposers(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.recomposers && data.data.recomposers.data) {
              data.data.recomposers.data.map((item) => {
                item.icon = item.avatar ? item.avatar.url : "https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg";
                item.value = {
                  id: item.id,
                  title: item.title
                }
              });
              return data.data.recomposers.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchFcats(q) {
      let query = `query($q: Mixed) {
        fcats(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.fcats && data.data.fcats.data) {
              return data.data.fcats.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchMelodies(q) {
      let query = `query($q: Mixed) {
        melodies(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.melodies && data.data.melodies.data) {
              return data.data.melodies.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    submit() {
      if ((this.upload.type === 'song' || this.upload.type === 'folk' || this.upload.type === 'instrumental' || this.upload.type === 'karaoke') && !this.upload.title) {
        this.$toast.error("Bạn chưa điền tên tác phẩm");
        return;
      }

      if ((this.upload.type === "document_image" || this.upload.type === "document_audio" || this.upload.type === "document_news") && !this.upload.title) {
        this.$toast.error("Bạn chưa điền tên tư liệu");
        return;
      }

      if (this.upload.type === "document_news" && !this.upload.content) {
        this.$toast.error("Bạn chưa nhập nội dung tư liệu");
        return;
      }

      if (this.upload.type === "sheet") {
        if (!this.upload.title) {
          this.$toast.error("Bạn chưa điền tên bản nhạc");
          return;
        }

        if (!this.upload.content) {
          this.$toast.error("Bạn chưa upload ảnh bản nhạc");
          return;
        }

        if (!this.upload.composer_list) {
          this.$toast.error("Bạn chưa chọn người sáng tác");
          return;
        }
      }

      if (this.upload.type === "replace" && !this.upload.link) {
        this.$toast.error("Bạn chưa điền link bài hát muốn thay thế");
        return;
      }

      if ((this.upload.type === 'song' || this.upload.type === 'folk' || this.upload.type === 'instrumental' || this.upload.type === 'karaoke' || this.upload.type === 'poem' || this.upload.type === 'replace' || this.upload.type === 'document_audio') && !this.upload.file_id) {
        this.$toast.error("Bạn chưa chọn file nhạc upload");
        return;
      }

      let query = `mutation(
        $title: String,
        $type: String!,
        $song_type: String,
        $folk_type: String,
        $karaoke_type: String,
        $file_id: Int,
        $image_id: Int,
        $content: String,
        $note: String,
        $year: String,
        $record_year: String,
        $composer_list: String,
        $user_list: String,
        $artist_list: String,
        $poet_list: String,
        $recomposer_list: String,
        $fcat_list: String,
        $melody_list: String,
        $composers: String,
        $users: String,
        $artists: String,
        $poets: String,
        $recomposers: String,
        $fcats: String,
        $melodies: String,
        $pre_composers: String,
        $pre_users: String,
        $pre_artists: String,
        $pre_poets: String
        $pre_recomposers: String
        $pre_fcats: String
        $pre_melodies: String
        $link: String
      ) {
        createUpload(input: {
          title: $title,
          type: $type,
          song_type: $song_type,
          folk_type: $folk_type,
          karaoke_type: $karaoke_type,
          file_id: $file_id,
          image_id: $image_id,
          content: $content,
          note: $note,
          year: $year,
          record_year: $record_year,
          composer_list: $composer_list,
          user_list: $user_list,
          artist_list: $artist_list,
          poet_list: $poet_list,
          fcat_list: $fcat_list,
          melody_list: $melody_list,
          recomposer_list: $recomposer_list,
          composers: $composers,
          users: $users,
          artists: $artists,
          poets: $poets,
          recomposers: $recomposers,
          fcats: $fcats,
          melodies: $melodies,
          pre_composers: $pre_composers,
          pre_users: $pre_users,
          pre_artists: $pre_artists,
          pre_poets: $pre_poets
          pre_recomposers: $pre_recomposers
          pre_fcats: $pre_fcats
          pre_melodies: $pre_melodies
          link: $link
        }) {
            id
        }
      }
      `;

      this.upload.file_id = parseInt(this.upload.file_id);
      this.upload.image_id = parseInt(this.upload.image_id);

      ApiService.graphql(query, this.upload)
          .then(({data}) => {
            if (data.data && data.data.createUpload) {
              this.$router.push({name: "UploadList"});
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.prefix = this.$route.params.prefix;
    if (this.$route.query.url) {
      this.upload.link = this.$route.query.url;
    }
    let user = getUser();
    if (this.prefix === "thanh-vien-hat") {
      this.upload.user_list_tmp.push({
        icon: user.avatar ? user.avatar.url : "https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg",
        username: user.username,
        id: user.id
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.prefix = to.params.prefix;
    if (to.query.url) {
      this.upload.link = to.query.url;
    }
    window.scrollTo(0, 0);
    next();
  },
  watch: {
    "upload.composer_list_tmp": {
      handler(newVal) {
        let ids = [];
        let composers = [];
        if (newVal) {
          for (let i = 0; i < newVal.length; i++) {
            ids.push(newVal[i].id);
            composers.push(newVal[i].title);
          }
        }
        this.upload.composer_list = ids.join(",");
        this.upload.composers = composers.join(" - ");
        this.upload.pre_composers = JSON.stringify(newVal);
      },
      deep: true
    },
    "upload.user_list_tmp": {
      handler(newVal) {
        let ids = [];
        let users = [];
        if (newVal) {
          for (let i = 0; i < newVal.length; i++) {
            ids.push(newVal[i].id);
            users.push(newVal[i].username);
          }
        }
        this.upload.user_list = ids.join(",");
        this.upload.users = users.join(" - ");
        this.upload.pre_users = JSON.stringify(newVal);
      },
      deep: true
    },
    "upload.artist_list_tmp": {
      handler(newVal) {
        let ids = [];
        let artists = [];
        if (newVal) {
          for (let i = 0; i < newVal.length; i++) {
            ids.push(newVal[i].id);
            artists.push(newVal[i].title);
          }
        }
        this.upload.artist_list = ids.join(",");
        this.upload.artists = artists.join(" - ");
        this.upload.pre_artists = JSON.stringify(newVal);
      },
      deep: true
    },
    "upload.poet_list_tmp": {
      handler(newVal) {
        let ids = [];
        let poets = [];
        if (newVal) {
          for (let i = 0; i < newVal.length; i++) {
            ids.push(newVal[i].id);
            poets.push(newVal[i].title);
          }
        }
        this.upload.poet_list = ids.join(",");
        this.upload.poets = poets.join(" - ");
        this.upload.pre_poets = JSON.stringify(newVal);
      },
      deep: true
    },
    "upload.recomposer_list_tmp": {
      handler(newVal) {
        let ids = [];
        let recomposers = [];
        if (newVal) {
          for (let i = 0; i < newVal.length; i++) {
            ids.push(newVal[i].id);
            recomposers.push(newVal[i].title);
          }
        }
        this.upload.recomposer_list = ids.join(",");
        this.upload.recomposers = recomposers.join(" - ");
        this.upload.pre_recomposers = JSON.stringify(newVal);
      },
      deep: true
    },
    "upload.fcat_list_tmp": {
      handler(newVal) {
        let ids = [];
        let fcats = [];
        if (newVal) {
          for (let i = 0; i < newVal.length; i++) {
            ids.push(newVal[i].id);
            fcats.push(newVal[i].title);
          }
        }
        this.upload.fcat_list = ids.join(",");
        this.upload.fcats = fcats.join(" - ");
        this.upload.pre_fcats = JSON.stringify(newVal);
      },
      deep: true
    },
    "upload.melody_list_tmp": {
      handler(newVal) {
        let ids = [];
        let melodies = [];
        if (newVal) {
          for (let i = 0; i < newVal.length; i++) {
            ids.push(newVal[i].id);
            melodies.push(newVal[i].title);
          }
        }
        this.upload.melody_list = ids.join(",");
        this.upload.melodies = melodies.join(" - ");
        this.upload.pre_melodies = JSON.stringify(newVal);
      },
      deep: true
    },
    prefix: {
      handler(newVal) {
        this.title = getUploadTitle(newVal);
        this.upload.type = getUploadType(newVal);
      }
    }
  },
  computed: {
    canUploadSong() {
      return canUploadSong();
    }
  }
}
</script>
